import Link from 'next/link';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { ButtonV2, ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';

import { useDelegatedTo } from '@/hooks/interactions/useDelegatedTo';
import { useFetchOperator } from '@/hooks/useFetchOperators';

import { api } from '@/utils/api';

type OperatorBlocklistWarningProps = {
  withLink?: boolean;
};

function OperatorBlocklistWarning({ withLink = false }: OperatorBlocklistWarningProps) {
  const { data: delegateAddress } = useDelegatedTo();
  const { data: isDelegatedToBlocklist } =
    api.operator.getIsDelegatedToBlocklist.useQuery(delegateAddress);
  const { operatorSummary } = useFetchOperator(delegateAddress);

  return isDelegatedToBlocklist ? (
    <div className="mb-4 flex flex-col items-center justify-between gap-5 rounded-lg border bg-white px-6 py-4 text-blue-700 sm:flex-row">
      <div className="flex items-center gap-5">
        <ExclamationTriangleIcon className="h-12 w-12" />
        <div className="flex w-full flex-col">
          <TextV2 intent="TextXL" weight="medium">
            {operatorSummary?.name ?? 'your selected operator'} is under review
          </TextV2>
          <TextV2 intent="TextS">
            This operator is under review for violation of either the terms of service or the
            content policy. Please exercise caution. Your funds remain safe and can be delegated to
            a different operator at any time.
          </TextV2>
        </div>
        {withLink && (
          <Link href={`/operator/${delegateAddress}`}>
            <ButtonV2 size={ComponentSize.SM}>Manage Delegation</ButtonV2>
          </Link>
        )}
      </div>
    </div>
  ) : null;
}

export default OperatorBlocklistWarning;
