'use client';

import { useEffect, useMemo, useState } from 'react';

import { Address } from 'viem';

import { TextV2, useSheet } from '@layr-labs/eigen-kit/react';

import { DashboardWithdrawalSheet } from 'components/Dashboard/DashboardWithdrawalSheet';
import RestakeTotalCard from 'components/Dashboard/RestakeCard';
import { RestakeTable } from 'components/Dashboard/RestakeTable';
import RewardsOverviewCard from 'components/Dashboard/RewardsOverviewCard';
import OperatorBlocklistWarning from 'components/OperatorBlocklistWarning';
import TokenTableModal from 'components/RestakeFlow/TokenTableModal';
import EarnTokenModal from 'components/Rewards/EarnTokenModal';
import LifetimeRewardsTable from 'components/Rewards/LifetimeRewardsTable';

import { useDelegatedTo } from '@/hooks/interactions/useDelegatedTo';
import useAccount from '@/hooks/useAccount';
import { useEarnTokenModal } from '@/hooks/useEarnTokenModal';
import useFetchEarnerAPRs from '@/hooks/useFetchEarnerAPRs';
import useFetchLastDelegationTimestamp from '@/hooks/useFetchLastDelegationTimestamp';
import { useFetchOperator } from '@/hooks/useFetchOperators';
import { useTimeToBlock } from '@/hooks/useTimeToBlock';
import { useTokens } from '@/hooks/useTokens';
import { useTokenStats } from '@/hooks/useTokenStats';
import useUncompletedWithdrawalsForAllStrategies from '@/hooks/useUncompletedWithdrawalsForAllStrategies';
import useUserBalances from '@/hooks/useUserBalances';

import { api } from '@/utils/api';
import { isToken } from '@/utils/index';
import { tokenRewardsToTokenSelections } from '@/utils/rewardsUtils';

import RestakePage from './restake/page';

import { env } from '@env';

import type { TokenSelection, UncompletedWithdrawal } from '@layr-labs/eigen-kit/types';
import type { Token } from 'classes/token';

type TokenWithWithdrawals = Token & {
  balance: bigint;
  completableWithdrawalAmount: bigint;
  pendingWithdrawalAmount: bigint;
  pendingWithdrawals: UncompletedWithdrawal[];
  apr: number;
  isRewardsPending?: boolean;
  earnedTokens: TokenSelection[];
  onEarnedTokensClick: (tokens: TokenSelection[], apr: number) => void;
};

export default function Page() {
  const sheet = useSheet();

  const { address, isConnected } = useAccount();

  const { data: tokenData = [] } = api.token.getTokens.useQuery({
    include_native: true,
  });

  const { earnerAPRs, isEarnerAPRsLoading } = useFetchEarnerAPRs(
    address as Address,
    tokenData.map((t) => t.strategyAddress),
  );

  const [nextRefetchNeeded, setNextRefetchNeeded] = useState<number | undefined>();

  const {
    isEarnTokensModalOpenClose,
    openCloseEarnTokensModal,
    earnTokensModalData,
    setEarnTokensModalData,
  } = useEarnTokenModal();

  const { isRewardsPending } = useFetchLastDelegationTimestamp(address);

  const {
    data: { liquidRestakingTokens, nativeRestakingToken },
    isLoading: isTokensLoading,
  } = useTokens();

  const { userTotalRestakedInETH, isLoading: isTokensStatsLoading } = useTokenStats();

  const { data: withdrawalData, isLoading: withdrawalDataIsLoading } =
    useUncompletedWithdrawalsForAllStrategies({
      refetchInterval: nextRefetchNeeded,
    });

  const {
    data: { liquidBalances },
  } = useUserBalances();

  const { data: delegateAddress, isDelegated } = useDelegatedTo();

  const { isOperatorLoading, operatorSummary } = useFetchOperator(delegateAddress);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState<string | undefined>();

  const usersStakedTokens = useMemo(() => {
    if (
      !liquidRestakingTokens ||
      !nativeRestakingToken ||
      !liquidBalances ||
      withdrawalDataIsLoading ||
      isEarnerAPRsLoading
    ) {
      return [];
    }

    const aprMap = new Map(earnerAPRs?.map((ea) => [ea?.strategyAddress, ea], []) ?? []);

    return [...liquidRestakingTokens, nativeRestakingToken]
      .filter(isToken)
      .map((token, index) => {
        const aprData = aprMap.get(token.strategyAddress);
        return {
          ...token,
          balance: token.symbol !== 'ETH' ? liquidBalances[index] : null,
          completableWithdrawalAmount: token.convertSharesToUnderlying(
            withdrawalData?.[token.address]?.completableWithdrawalAmount,
          ),
          pendingWithdrawalAmount: token.convertSharesToUnderlying(
            withdrawalData?.[token.address]?.pendingWithdrawalAmount,
          ),
          pendingWithdrawals: withdrawalData?.[token.address]?.pendingWithdrawals ?? [],
          isRewardsPending: isRewardsPending,
          apr: aprData ? Number(aprData.apr ?? 0) : 0,
          earnedTokens: aprData ? tokenRewardsToTokenSelections(aprData.rewards ?? []) : [],
          onEarnedTokensClick: (tokens, apr) => {
            openCloseEarnTokensModal();
            setSelectedTokenSymbol(token.symbol);
            setEarnTokensModalData({
              tokens,
              apr,
            });
          },
        };
      })
      .filter(
        (token) =>
          !!token.deposited.underlying ||
          !!token.pendingWithdrawalAmount ||
          !!token.completableWithdrawalAmount ||
          !!token.balance,
      ) as TokenWithWithdrawals[];
  }, [
    liquidRestakingTokens,
    nativeRestakingToken,
    liquidBalances,
    withdrawalDataIsLoading,
    isEarnerAPRsLoading,
    earnerAPRs,
    withdrawalData,
    isRewardsPending,
    openCloseEarnTokensModal,
    setEarnTokensModalData,
  ]);

  const [pendingWithdrawals, completableWithdrawals, deposit] = useMemo(() => {
    return usersStakedTokens.reduce(
      ([pending, completable, deposit], nextToken) => {
        if (nextToken.pendingWithdrawalAmount) pending.push(nextToken);
        if (nextToken.completableWithdrawalAmount) completable.push(nextToken);
        if (nextToken.deposited.underlying) {
          deposit.push(nextToken.deposited.underlying);
        }
        return [pending, completable, deposit];
      },
      [[], [], []] as [TokenWithWithdrawals[], TokenWithWithdrawals[], bigint[]],
    );
  }, [usersStakedTokens]);

  const nearestCompletableBlock = useMemo(() => {
    return Math.min(
      ...pendingWithdrawals.map((withdrawal) => {
        return withdrawal.pendingWithdrawals.reduce((acc, next) => {
          return Math.min(acc, Number(next.completableBlock));
        }, Number.MAX_SAFE_INTEGER);
      }),
    );
  }, [pendingWithdrawals]);

  const { currentBlock, data: time } = useTimeToBlock({
    targetBlock: nearestCompletableBlock,
    useBlockNumberParams: { query: { refetchInterval: 60000 } },
    format: 'date',
  });

  useEffect(() => {
    if (!currentBlock || !pendingWithdrawals.length) return;

    if (nearestCompletableBlock !== Number.MAX_SAFE_INTEGER && time) {
      setNextRefetchNeeded(+time - Date.now() + 10);
    } else {
      setNextRefetchNeeded(undefined);
    }
  }, [currentBlock, time, nearestCompletableBlock, pendingWithdrawals.length]);

  return (
    <div className="mb-4 flex flex-col gap-4">
      <EarnTokenModal
        title={`${selectedTokenSymbol} rewards`}
        earnTokenModalTableData={earnTokensModalData?.tokens}
        apr={earnTokensModalData?.apr}
        isOpen={isEarnTokensModalOpenClose}
        onClose={openCloseEarnTokensModal}
      />
      <OperatorBlocklistWarning />
      <div className="flex items-center justify-between">
        <TextV2 intent="DisplayS" weight="medium" className="text-blue-800">
          Dashboard
        </TextV2>
        <TokenTableModal />
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <RestakeTotalCard
          className="bg-white md:basis-1/2"
          totalRestakedData={{
            isTokensStatsLoading,
            userTotalRestakedInETH,
            usersStakedTokens,
          }}
          canUnstake={deposit.length > 0}
          isDelegated={isDelegated}
          operatorSummary={operatorSummary}
          isOperatorLoading={isOperatorLoading}
          isLoading={isTokensLoading || (withdrawalDataIsLoading && !!address)}
          isConnected={isConnected}
          pendingWithdrawals={pendingWithdrawals}
          completableWithdrawals={completableWithdrawals}
          completableSheetDisplay={() => {
            sheet.showSheet({
              header: (
                <DashboardWithdrawalSheet.Header
                  title="Available to withdraw"
                  onClose={sheet.hideSheet}
                />
              ),
              body: (
                <DashboardWithdrawalSheet.Body
                  withdrawalFieldKey="completableWithdrawal"
                  onClose={sheet.hideSheet}
                />
              ),
            });
          }}
        />
        <RewardsOverviewCard className="bg-white-950 md:basis-1/2" />
      </div>
      {!isConnected || usersStakedTokens.length === 0 ? (
        <RestakePage />
      ) : (
        <RestakeTable
          isConnected={isConnected}
          usersStakedTokens={usersStakedTokens}
          isLoading={
            (withdrawalDataIsLoading && !!address) || isTokensLoading || isEarnerAPRsLoading
          }
        />
      )}
      {env.NEXT_PUBLIC_INCENTIVES_VERSION !== 'v0' && <LifetimeRewardsTable />}
    </div>
  );
}
