import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { TextV2, TooltipV2 } from '@layr-labs/eigen-kit/react';

function EarnTokenModalTitle() {
  return (
    <TextV2 intent="TextXS" font="ibmPlexMono" className="flex gap-1 text-blue-400">
      Historical APR
      <TooltipV2>
        <TooltipV2.Trigger>
          <InformationCircleIcon className="h-4 w-4 text-blue-400" />
        </TooltipV2.Trigger>
        <TooltipV2.Content>
          <div className="w-64 text-center text-blue-800">
            <TextV2 intent="TextS">
              Your estimated annualized historical 7-day reward rate for delegating the selected
              asset to this Operator.
            </TextV2>
          </div>
        </TooltipV2.Content>
      </TooltipV2>
    </TextV2>
  );
}

export default EarnTokenModalTitle;
