import { useCallback, useState } from 'react';

import { TokenSelection } from '@layr-labs/eigen-kit/types';

export function useEarnTokenModal() {
  const [isEarnTokensModalOpenClose, setIsEarnTokensModalOpeClose] = useState(false);
  const [earnTokensModalData, setEarnTokensModalData] = useState<{
    tokens: Array<TokenSelection>;
    apr?: number;
    title?: string;
  } | null>(null);

  const openCloseEarnTokensModal = useCallback(
    () => setIsEarnTokensModalOpeClose((prev) => !prev),
    [setIsEarnTokensModalOpeClose],
  );

  return {
    isEarnTokensModalOpenClose,
    openCloseEarnTokensModal,
    earnTokensModalData,
    setEarnTokensModalData,
  };
}
