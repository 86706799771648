'use client';

import Image from 'next/image';

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { TextV2, TransactionLink } from '@layr-labs/eigen-kit/react';
import { TransactionStatus } from '@layr-labs/eigen-kit/types';

import { ClaimTransaction } from './useClaimFlowStore';

import { env } from '@env';

function TransactionReceipt({
  configError,
  transaction,
}: {
  configError: string | undefined;
  transaction: ClaimTransaction;
}) {
  if (configError) {
    return (
      <div className="w-full px-4 pt-4">
        <div className="flex flex-col gap-2">
          <XCircleIcon className="h-14 w-14 text-red-400" />
          <TextV2 intent="DisplayS">Failed to claim rewards</TextV2>
          <TextV2 intent="TextS" className="max-h-20 overflow-auto">
            {configError || 'An error occurred, please try again later.'}
          </TextV2>
        </div>
      </div>
    );
  }

  const transactionLink = transaction.hash && (
    <TransactionLink
      hash={transaction.hash}
      blockExplorerURL={env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}
    />
  );

  return (
    <div className="w-full px-4 pt-4">
      {transaction.status === TransactionStatus.Pending && (
        <div className="flex flex-col gap-2">
          <Image
            src="/eigenGif/Blue-Eigen-Loader.gif"
            height={64}
            width={64}
            alt="eigenlayer loader"
          />
          {transaction.message || 'Loading...'}
          {transactionLink}
        </div>
      )}
      {transaction.status === TransactionStatus.Success && (
        <div className="flex flex-col gap-2">
          <CheckCircleIcon className="h-14 w-14 text-green-400" />
          <TextV2 intent="DisplayS">{transaction.message || 'Success!'}</TextV2>
          {transactionLink}
        </div>
      )}

      {transaction.status === TransactionStatus.Failed && (
        <div className="flex flex-col gap-4">
          <XCircleIcon className="h-14 w-14 text-red-400" />
          <TextV2 intent="TextL">Failed to claim rewards</TextV2>
          <TextV2 intent="TextS" className="overflow-auto">
            {transaction.message || 'An error occurred, please try again later.'}
          </TextV2>
          {transactionLink}
        </div>
      )}
    </div>
  );
}

export default TransactionReceipt;
