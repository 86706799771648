'use client';

import { Fragment, memo, useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { Address } from 'viem';

import { NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { TokenSelection } from '@layr-labs/eigen-kit/types';

import useAccount from '@/hooks/useAccount';
import useFetchLastDelegationTimestamp from '@/hooks/useFetchLastDelegationTimestamp';

import { tempAPRMax } from '@/utils/rewardsUtils';

import EarnTokenModalTitle from './EarnTokenModalTitle';
import RewardsTable from './RewardsTable';

type EarnTokenModalProps = {
  apr?: number;
  earnTokenModalTableData?: TokenSelection[];
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  isOnlyTokens?: boolean;
};

function EarnTokenModal({
  apr,
  earnTokenModalTableData = [],
  title,
  isOpen,
  onClose,
  isOnlyTokens,
}: EarnTokenModalProps) {
  const [data, setData] = useState<TokenSelection[]>([]);

  const { isConnected, address } = useAccount();

  useEffect(() => {
    if (earnTokenModalTableData && earnTokenModalTableData.length > 0) {
      setData(earnTokenModalTableData);
    }
  }, [earnTokenModalTableData]);

  const { isRewardsPending } = useFetchLastDelegationTimestamp(address as Address);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed top-0 z-10 flex h-screen w-screen items-center justify-center bg-black-200"
          onClick={onClose}
        />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-20 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-4 rounded-lg bg-white outline-none sm:top-1/3 sm:mt-20 sm:h-[450px] sm:w-[420px]">
          {!isConnected ? (
            <div className="flex h-full w-full flex-col gap-4 rounded-lg border border-blue-100 bg-white-950 px-4 py-6">
              <div>
                <TextV2 intent="TextXL" weight="medium">
                  {title}
                </TextV2>
              </div>
              <div className="flex flex-col gap-2 rounded-lg border border-blue-100 px-4 py-6">
                <EarnTokenModalTitle />

                <div>- - -</div>
              </div>
            </div>
          ) : (
            <div className="flex h-full w-full flex-col gap-4 rounded-lg border border-blue-100 bg-white-950 px-4 py-6">
              <div>
                <TextV2 intent="TextXL" weight="medium">
                  {title}
                </TextV2>
              </div>
              {isOnlyTokens ? (
                <RewardsTable rewardData={data} />
              ) : (
                <>
                  <div className="flex flex-col gap-2 rounded-lg border border-blue-100 px-4 py-6">
                    <EarnTokenModalTitle />
                    {isRewardsPending ? (
                      <Fragment>
                        <TextV2 intent="DisplayXS" weight="medium" className="mr-1.5">
                          Pending
                        </TextV2>
                        <TextV2 intent="TextXS" className="text-blue-400">
                          Rewards may take up to 48 hours to be reflected
                        </TextV2>
                      </Fragment>
                    ) : (
                      <TextV2
                        intent="DisplayXS"
                        weight="medium"
                        className="mr-1.5"
                        dataCypress="restakeBalance"
                      >
                        {tempAPRMax(apr ?? 0) ? (
                          <Fragment>
                            <NumberDisplay
                              value={Number(apr ?? 0) * 100}
                              decimals={4}
                              format="tokenAmount"
                            />
                            %
                          </Fragment>
                        ) : (
                          'N/A'
                        )}
                      </TextV2>
                    )}
                  </div>
                  {data.length > 0 && <RewardsTable rewardData={data} hiddenCols={['weiAmount']} />}
                </>
              )}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default memo(EarnTokenModal);
