'use client';

import {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

import * as Dialog from '@radix-ui/react-dialog';

import { ButtonV2, ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';
import { asType } from '@layr-labs/eigen-kit/util';
import { token } from '@layr-labs/eigen-kit/types';

import ConnectWallet from 'components/ConnectWallet';
import RewardTokenModal from 'components/Rewards/RewardTokenModal';
import TokenTable from 'components/Token/TokenTable';

import useRewardTokenModal from '@/hooks/useRewardTokenModal';

import { Actions } from '.';

const DefaultModalTriggerButton = forwardRef<HTMLButtonElement, { onClick: () => void }>(
  (props, ref) => {
    return (
      <ButtonV2
        intent="primary"
        ref={ref}
        className="min-w-[104px] uppercase text-white"
        size={ComponentSize.SM}
        onClick={props.onClick}
      >
        Restake
      </ButtonV2>
    );
  },
);

DefaultModalTriggerButton.displayName = 'DefaultModalTriggerButton';

export default function TokenTableModal({
  action = 'deposit',
  ModalTriggerButton = DefaultModalTriggerButton,
}: {
  action?: `${Actions}`;
  ModalTriggerButton?: ForwardRefExoticComponent<
    { onClick: () => void } & RefAttributes<HTMLButtonElement>
  >;
}) {
  const {
    isRewardTokenModalOpen,
    rewardTokenModalData,
    toggleRewardTokenModal,
    depositModalButton,
  } = useRewardTokenModal();

  const searchParams = useSearchParams();

  const searchParamFlow = searchParams?.get('flow') === 'true';

  const isFlow = useMemo(() => {
    return searchParamFlow && action === 'deposit';
  }, [searchParamFlow, action]);

  const [open, setOpen] = useState(isFlow);
  const [animation, setAnimation] = useState('animate-fadeIn');

  useEffect(() => {
    if (open) {
      setAnimation('animate-fadeIn');
    }
  }, [open]);

  const router = useRouter();
  const handleCloseModal = () => {
    router.replace('/');
    setAnimation('animate-fadeOut');
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  return (
    <Dialog.Root open={open}>
      <RewardTokenModal
        headerTitle={`Reward tokens for ${rewardTokenModalData.symbol}`}
        isOpen={isRewardTokenModalOpen}
        onClose={toggleRewardTokenModal}
        rewardTokenModalTableData={rewardTokenModalData.rewardTokens}
        depositOrDelegateButton={depositModalButton}
      />
      <Dialog.Trigger asChild>
        <ModalTriggerButton onClick={() => setOpen(true)} />
      </Dialog.Trigger>
      <Dialog.Content
        onEscapeKeyDown={handleCloseModal}
        className={`fixed left-0 top-0 z-10 flex h-screen w-screen flex-col bg-blue-800 pt-5 sm:pt-8 ${(!isFlow || animation === 'animate-fadeOut') && animation}`}
      >
        <div className="mb-10 flex w-full items-center justify-between px-8">
          <div
            onClick={handleCloseModal}
            className="w-fit cursor-pointer text-white-500 hover:text-white-500/80"
          >
            ← Back
          </div>
          <div className="w-fit text-white">
            <ConnectWallet
              intent="secondary"
              connectedClassName="text-white-400 hover:bg-white-100 hover:border-transparent py-1 px-3 hover:text-white-950"
              disconnectedClassName="text-white-400 border-transparent hover:bg-white-100 hover:border-transparent py-1 px-3 hover:text-white-950"
            />
          </div>
        </div>
        <div className="h-full w-full px-8">
          <div className="flex h-full w-full flex-col">
            <div className="mb-5 flex text-white">
              <TextV2 intent="DisplayS">Select an Asset</TextV2>
            </div>
            <div className="w-full rounded-lg bg-white">
              <TokenTable
                page="dashboard"
                rowSelection="single"
                onRowSelected={(event) => {
                  const token = asType<token>(event?.data);
                  const { slug } = token;
                  router.push(
                    `/restake/${slug}${slug === 'ETH' ? '' : `?action=${action}&flow=true`}`,
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
