import { forwardRef } from 'react';

import { ButtonV2, ComponentSize, NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';
import { token, UncompletedWithdrawal } from '@layr-labs/eigen-kit/types';

import { OperatorLink } from 'components/Operator/OperatorLink';
import TokenTableModal from 'components/RestakeFlow/TokenTableModal';

import { isLSTorNative } from '@/utils/index';

import TokenIconDisplay from '../Token/TokenIconDisplay';
import LoadingCard from './LoadingCard';

type RestakeTokenType = token & {
  balance: bigint;
  completableWithdrawalAmount: bigint;
  pendingWithdrawalAmount: bigint;
  pendingWithdrawals: UncompletedWithdrawal[];
};

type RestakeCardProps = {
  className?: string;
  totalRestakedData: {
    isTokensStatsLoading: boolean;
    userTotalRestakedInETH: number;
    usersStakedTokens: Array<token>;
  };
  isDelegated: boolean;
  canUnstake: boolean;
  operatorSummary: {
    address: string;
    logo: string;
    name: string;
  } | null;
  isOperatorLoading: boolean;
  pendingWithdrawals: Array<RestakeTokenType>;
  completableWithdrawals: Array<RestakeTokenType>;
  completableSheetDisplay: () => void;
  isConnected: boolean;
  isLoading: boolean;
};

export default function RestakeCard({
  className,
  totalRestakedData,
  completableSheetDisplay,
  completableWithdrawals,
  pendingWithdrawals,
  operatorSummary,
  isConnected,
  isDelegated,
  canUnstake,
  isLoading,
}: RestakeCardProps) {
  if (isLoading) {
    return <LoadingCard className={className} />;
  }
  return (
    <div className={cn('flex flex-col gap-2 rounded-lg p-4', className)}>
      <div
        data-testid="total-restake-card"
        className="mb-4 flex flex-col justify-between gap-1 px-2 md:mb-4"
      >
        <TextV2 intent="TextXS" font="ibmPlexMono" className="uppercase text-blue-400">
          Total Restaked
        </TextV2>
        <TextV2
          intent="DisplayS"
          className="uppercase text-blue-800"
          weight="medium"
          dataCypress="restakedBalance"
        >
          <NumberDisplay value={totalRestakedData.userTotalRestakedInETH} format="tokenAmount" />
          <TextV2 intent="DisplayS" className="text-blue-400">
            {' '}
            ETH
          </TextV2>
        </TextV2>
        {isConnected &&
          totalRestakedData.usersStakedTokens
            .filter((t) => !isLSTorNative(t))
            .map(
              ({ deposited, symbol }) =>
                deposited &&
                deposited?.underlying > 0n && (
                  <TextV2 key={symbol} intent="TextS" className="uppercase text-blue-800">
                    <NumberDisplay value={deposited.underlying ?? 0n} format="tokenAmount" />{' '}
                    {symbol}
                  </TextV2>
                ),
            )}
      </div>
      <div className="flex flex-col sm:mt-auto">
        {isConnected && (
          <div className="flex flex-row items-center justify-between gap-1 border-t border-blue-100 p-2">
            <TextV2 intent="TextS" className="my-0.5 text-blue-400">
              Delegated to
            </TextV2>
            {isDelegated ? (
              <TextV2 intent="TextS" weight="medium" className="my-0.5">
                <OperatorLink
                  name={operatorSummary?.name}
                  address={operatorSummary?.address}
                  logo={operatorSummary?.logo}
                />
              </TextV2>
            ) : (
              <TextV2 intent="TextS" className="my-0.5 text-blue-400">
                Not Delegated
              </TextV2>
            )}
          </div>
        )}
        {isConnected && pendingWithdrawals.length > 0 && (
          <div className="flex items-center justify-between border-t border-blue-100 p-2">
            <TextV2 intent="TextS" className="text-blue-400">
              In Escrow
            </TextV2>
            {/* NOTE: adding py-0.5 + to other "buttons" to account for the SVG icon size being 4px taller then TextS text */}
            <TokenIconDisplay
              tokenIcons={pendingWithdrawals
                .sort((a, b) => (a.pendingWithdrawalAmount < b.pendingWithdrawalAmount ? -1 : 1))
                .map(({ icon }) => icon)}
            />
          </div>
        )}
        {isConnected && completableWithdrawals.length > 0 && (
          <div className="flex items-center justify-between border-t border-blue-100 p-2">
            <TextV2 intent="TextS" className="text-blue-400">
              Available to withdraw
            </TextV2>
            {/* NOTE: adding py-0.5 + to other "buttons" to account for the SVG icon size being 4px taller then TextS text */}
            <TokenIconDisplay
              tokenIcons={completableWithdrawals
                .sort((a, b) =>
                  a.completableWithdrawalAmount < b.completableWithdrawalAmount ? -1 : 1,
                )
                .map(({ icon }) => icon)}
            />
          </div>
        )}
      </div>
      <div className="flex flex-grow-0 flex-col gap-2 sm:flex-row">
        <TokenTableModal
          action="unstake"
          // eslint-disable-next-line react/display-name
          ModalTriggerButton={forwardRef<HTMLButtonElement, { onClick: () => void }>(
            (props, ref) => {
              return (
                <ButtonV2
                  intent="secondary"
                  ref={ref}
                  disabled={!canUnstake}
                  className="w-full uppercase sm:w-1/2"
                  size={ComponentSize.XS}
                  onClick={props.onClick}
                >
                  Unstake
                </ButtonV2>
              );
            },
          )}
        />
        <ButtonV2
          intent="secondary"
          className="w-full flex-grow-0 uppercase sm:w-1/2"
          size={ComponentSize.XS}
          onClick={completableSheetDisplay}
          disabled={!completableWithdrawals.length}
        >
          Withdraw
        </ButtonV2>
      </div>
    </div>
  );
}
