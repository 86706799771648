'use client';

import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { ColDef, ColumnState, GridReadyEvent } from 'ag-grid-community';
import { useLocalStorage } from 'usehooks-ts';

import {
  ButtonV2,
  ComponentSize,
  PaginationV2,
  TextV2,
  TooltipV2,
  ValueLoader,
} from '@layr-labs/eigen-kit/react';
import { token, TokenSelection } from '@layr-labs/eigen-kit/types';

import TokenIcon from 'components/Token/Icon';

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import 'components/Table/eigen-table.css';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { AgGridReactProps } from 'ag-grid-react';
import { props } from 'node_modules/cypress/types/bluebird';

import CellComponent from 'components/Table/CellComponent';
import HeaderWithSort, { HeaderWithSortIcon } from 'components/Table/HeaderWithSort';
import TokenIconDisplay from 'components/Token/TokenIconDisplay';

type UsersStakedTokenType = token & {
  completableWithdrawalAmount?: bigint;
  pendingWithdrawalAmount?: bigint;
  isRewardsPending?: boolean;
  apr?: number;
  earnedTokens?: Array<TokenSelection>;
  onEarnedTokensClick?: (tokens: Array<TokenSelection>, apr?: number) => void;
};

type RestakeTableProps = {
  usersStakedTokens: Array<UsersStakedTokenType>;
  isConnected: boolean;
  isLoading: boolean;
};

const AgGridReact = dynamic<AgGridReactProps<UsersStakedTokenType>>(
  () => import('ag-grid-react').then((mod) => mod.AgGridReact),
  {
    ssr: false,
    loading: () => (
      <>
        <ValueLoader intent="TextL" className="mb-2 w-1/4" />
        <ValueLoader className="h-56" />
      </>
    ),
  },
);

const colDefs: ColDef<UsersStakedTokenType, unknown>[] = [
  {
    field: 'name',
    headerName: 'Asset',
    resizable: false,
    flex: 2,
    minWidth: 100,
    suppressMovable: true,
    cellRenderer: (props) => (
      <Link href={`/restake/${props.data.slug}`} className="mx-auto flex h-full items-center gap-2">
        <TokenIcon src={props.data.icon} symbol={props.data.symbol} size={ComponentSize.XS} />
        <TextV2
          intent="TextXS"
          weight="medium"
          className="hidden text-blue-700 underline-offset-1 lg:block"
          dataCypress="dashboardTokenName"
        >
          {props.value}
        </TextV2>
        <TextV2 intent="TextXS" className="text-blue-400">
          {props.data.symbol}
        </TextV2>
      </Link>
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'balance',
    headerName: 'Wallet Balance',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 150,
    wrapHeaderText: true,
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => (
      <CellComponent {...props} format="tokenAmount" showDash={props?.data.symbol === 'ETH'} />
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'completableWithdrawalAmount',
    headerName: 'Available to Withdraw',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 200,
    wrapHeaderText: true,
    wrapText: true,
    autoHeight: true,
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => (
      <CellComponent
        {...props}
        format="tokenAmount"
        dataCypress={`availableWithdrawalCell-${props.data.symbol}`}
      />
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'pendingWithdrawalAmount',
    headerName: 'Pending Withdraw',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 150,
    wrapHeaderText: true,
    autoHeight: true,
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => <CellComponent {...props} format="tokenAmount" />,
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'deposited.underlying',
    headerName: 'Restaked Balance',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 150,
    wrapHeaderText: true,
    autoHeight: true,
    comparator: (a, b) => Number(a) - Number(b),
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    cellRenderer: (props) => <CellComponent {...props} format="tokenAmount" />,
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'earnedTokens',
    headerName: 'Historical APR',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 200,
    wrapHeaderText: true,
    autoHeight: true,
    cellClass: 'reward-token-cell',
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const aprA = nodeA?.data?.apr ?? 0;
      const aprB = nodeB?.data?.apr ?? 0;
      if (Number(aprA) === Number(aprB)) {
        return (nodeA?.data?.earnedTokens?.length ?? 0) - (nodeB?.data?.earnedTokens?.length ?? 0);
      }
      return aprA - aprB;
    },
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    headerComponent: (props) => (
      <HeaderWithSort
        props={props}
        className="ag-header-cell-label flex items-center gap-2 text-right"
      >
        <TextV2
          intent="TextXS"
          className="text-left font-ibmPlexMono font-normal uppercase text-blue-400"
        >
          {props.displayName}
        </TextV2>
        <TooltipV2>
          <TooltipV2.Trigger>
            <InformationCircleIcon className="h-4 w-4 text-blue-400" />
          </TooltipV2.Trigger>
          <TooltipV2.Content>
            <div className="w-64 text-center text-blue-800">
              <TextV2 intent="TextS">
                Your estimated annualized historical 7-day reward rate for delegating the selected
                asset to this Operator.
              </TextV2>
            </div>
          </TooltipV2.Content>
        </TooltipV2>
      </HeaderWithSort>
    ),
    cellRenderer: (props) =>
      props.value.length > 0 ? (
        props.data.isRewardsPending ? (
          <ButtonV2
            intent="pill"
            className="h-8 items-center border-none hover:bg-blue-50 active:bg-blue-50"
            size={ComponentSize.XS}
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                props.data.onEarnedTokensClick(props.data.earnedTokens, props.data.apr);
              };
            }}
          >
            <TextV2 intent="TextS" className="text-blue-400">
              Pending
            </TextV2>
          </ButtonV2>
        ) : (
          <ButtonV2
            intent="pill"
            size={ComponentSize.XS}
            className="mr-6 flex h-8 items-center justify-center rounded-3xl p-1.5"
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                e.preventDefault();
                props.data.onEarnedTokensClick(props.data.earnedTokens, props.data.apr);
              };
            }}
          >
            <TokenIconDisplay
              tokenIcons={props.data.earnedTokens.map((t) => t.icon)}
              apr={props.data.apr}
            />
          </ButtonV2>
        )
      ) : (
        <TextV2 intent="TextXS" className="mr-6 text-blue-400">
          &#45;
        </TextV2>
      ),
  },
];

export function RestakeTable({
  usersStakedTokens = [],
  isConnected = false,
  isLoading = true,
}: RestakeTableProps) {
  const [data, setData] = useState<Array<UsersStakedTokenType>>(() => []);
  const [sort, setSort] = useLocalStorage<ColumnState | null>('table-restake', null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gridRef = useRef<GridReadyEvent<UsersStakedTokenType, any> | null>(null);

  const router = useRouter();

  useEffect(() => {
    if (usersStakedTokens) {
      setData(usersStakedTokens);
    }
  }, [usersStakedTokens]);

  const isConnectedWithNoTokens = isConnected && data.length === 0 && !isLoading;

  if (isLoading) {
    return (
      <div className="flex h-full flex-col gap-4 rounded-lg bg-white p-2">
        <ValueLoader intent="TextL" className="mt-2 w-1/4" />
        <ValueLoader className="h-56" />
      </div>
    );
  }

  if (!isConnected || isConnectedWithNoTokens) {
    return (
      <div className="flex flex-col justify-between rounded-lg bg-white p-2">
        <TextV2 intent="TextL" className="mx-2 mb-2 text-blue-800">
          Restaked Assets
        </TextV2>
        <div className="mx-auto flex h-56 flex-col items-center justify-center md:w-1/2">
          <TextV2 intent="DisplayS" className="text-blue-400">
            No assets to display
          </TextV2>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between gap-2 rounded-lg bg-white p-2">
      <TextV2 intent="TextL" className="mx-2 text-blue-800">
        Restaked Assets
      </TextV2>
      <div className="ag-theme-quartz ag-theme-eigen min-h-12 grow">
        {/* TOOD: loadingOverlayComponent and  noRowsOverlayComponent */}
        <AgGridReact
          rowData={data}
          domLayout="autoHeight"
          rowClass="hover:bg-blue-100 cursor-pointer"
          columnDefs={colDefs}
          onRowClicked={(event) => {
            const data = event?.data as UsersStakedTokenType;
            data?.address && router.push(`/restake/${data?.slug}`);
          }}
          onGridReady={(event) => {
            if (sort) {
              event.api.applyColumnState({
                state: [sort],
              });
            } else if (isConnected && !sort) {
              event.api.applyColumnState({
                state: [
                  {
                    colId: 'deposited.underlying',
                    sort: 'desc',
                  },
                ],
              });
            } else {
              event.api.applyColumnState({
                state: [
                  {
                    colId: 'earnedTokens',
                    sort: 'desc',
                  },
                ],
              });
            }

            setCurrentPage(event.api.paginationGetCurrentPage());
            setTotalPages(event.api.paginationGetTotalPages());
          }}
          onSortChanged={(event) => {
            setSort(event.api.getColumnState().find((col) => Boolean(col.sort)) ?? null);
          }}
          paginationPageSize={20}
          animateRows={false}
          pagination={true}
          suppressPaginationPanel={true}
          onPaginationChanged={(event) => {
            setCurrentPage(event.api.paginationGetCurrentPage());
            setTotalPages(event.api.paginationGetTotalPages());
          }}
        />
      </div>
      <PaginationV2
        totalPages={totalPages}
        pageIndex={currentPage}
        truncate
        onPageChange={(page) => {
          gridRef.current?.api.paginationGoToPage(page);
        }}
      />
    </div>
  );
}
