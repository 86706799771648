import { ButtonV2, NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { TokenSelection } from '@layr-labs/eigen-kit/types';

import TokenIcon from './Icon';

function TokenRewardsDisplay({
  tokenClaims,
  onClaimsModalOpenClose,
}: {
  tokenClaims: TokenSelection[];
  onClaimsModalOpenClose: () => void;
}) {
  let countText = '';
  if (tokenClaims.length === 0) {
    return null;
  }

  if (tokenClaims.length > 3) {
    countText = `+${tokenClaims.length - 3}`;
    tokenClaims = tokenClaims.slice(0, 3);
  }

  return (
    <div className="hidden flex-wrap justify-end gap-2 md:flex">
      {tokenClaims.length > 0
        ? tokenClaims.map(({ icon, address, weiAmount }) => {
            return (
              <div key={address} className="flex items-center gap-1">
                {icon !== '' ? (
                  <TokenIcon
                    src={icon}
                    fill={false}
                    className="h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-100"
                  />
                ) : (
                  <div className="h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-300" />
                )}
                <TextV2 intent="TextS" className="uppercase text-blue-800">
                  <NumberDisplay value={weiAmount} format="tokenAmount" />
                </TextV2>
              </div>
            );
          })
        : null}
      {countText && (
        <ButtonV2
          intent="pill"
          className="hidden px-2 py-0.5 md:flex"
          onClick={onClaimsModalOpenClose}
        >
          <TextV2 intent="TextXS" className="text-blue-800">
            {countText} more
          </TextV2>
        </ButtonV2>
      )}
    </div>
  );
}

export default TokenRewardsDisplay;
