import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';

import { XCircleIcon } from '@heroicons/react/24/outline';

import {
  ButtonV2,
  Checkbox,
  ComponentSize,
  NumberDisplay,
  TextV2,
} from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';
import {
  UncompletedMultiAssetWithdrawalsWithToken,
  UncompletedWithdrawalsWithToken,
  UncompletedWithdrawalType,
} from '@layr-labs/eigen-kit/types';

import TokenIconDisplay from 'components/Token/TokenIconDisplay';

import { useCompleteWithdrawals } from '@/hooks/interactions/useCompleteWithdrawals';
import { useGetAllWithdrawalBuckets } from '@/hooks/useGetAllWithdrawalBuckets';
import useLiquidTokens from '@/hooks/useLiquidTokens';
import useNativeToken from '@/hooks/useNativeToken';
import { useTimeToBlock } from '@/hooks/useTimeToBlock';

/**
 * The props for the sheet header that displays the user's pending/completable withdrawals
 * @typedef {Object} DashboardWithdrawalSheetHeaderProps
 */
export interface DashboardWithdrawalSheetHeaderProps {
  /**
   * The title of the sheet
   */
  title: React.ReactNode;
  /**
   * The function to close the sheet
   */
  onClose: () => void;
}

/**
 * The props for the sheet body that displays the user's pending/completable withdrawals
 * @typedef {Object} DashboardWithdrawalSheetBodyProps
 * @property {"completableWithdrawal" | "pendingWithdrawal"} withdrawalFieldKey -
 * @property {() => void} onClose - The function to close the sheet
 */
export interface DashboardWithdrawalSheetBodyProps {
  /**
   * The base root of the keys used to access relevant fields within the withdrawal
   */
  withdrawalFieldKey: 'completableWithdrawal' | 'pendingWithdrawal';
  /**
   * The function to close the sheet
   */
  onClose: () => void;
}

// ===========================
//    Main Components
// ===========================

/**
 * The header of the sheet that displays the user's pending/completable withdrawals
 * @param {DashboardWithdrawalSheetHeaderProps} props - The props for the header
 * @returns {JSX.Element} The header component
 * @example
 * ```tsx
 * <DashboardWithdrawalSheet.Header
 *   ref={ref}
 *   title="Withdrawals"
 *   onClose={onClose}
 * />
 * ```
 */
const DashboardWithdrawalSheetHeader = memo(
  forwardRef<HTMLDivElement, DashboardWithdrawalSheetHeaderProps>(({ title, onClose }, ref) => (
    <div ref={ref} className="flex h-8 max-h-8 min-h-8 flex-row items-center justify-between">
      <TextV2 className="text-TextXL font-bold text-blue-800 sm:text-DisplayXS">{title}</TextV2>
      <XCircleIcon
        onClick={onClose}
        className="h-8 w-8 text-blue-800 hover:cursor-pointer max-sm:translate-x-2"
      />
    </div>
  )),
);
DashboardWithdrawalSheetHeader.displayName = 'DashboardWithdrawalSheetHeader';

/**
 * The body of the sheet that displays the user's pending/completable withdrawals
 * @param {DashboardWithdrawalSheetBodyProps} props - The props for the body
 * @returns {JSX.Element} The body component
 * @example
 * ```tsx
 * <DashboardWithdrawalSheet.Body
 *  withdrawalFieldKey="completableWithdrawal"
 *  onClose={onClose}
 * />
 */
export const DashboardWithdrawalSheetBody = memo(
  ({ withdrawalFieldKey, onClose }: DashboardWithdrawalSheetBodyProps) => {
    /**
     * The key for the withdrawal field
     */
    const canCompleteWithdraw = withdrawalFieldKey === 'completableWithdrawal';

    /**
     * The reference to the footer element used for height calculations
     */
    const footerRef = useRef<HTMLDivElement>(null);
    /**
     * The height of the footer
     */
    const [footerHeight, setFooterHeight] = useState(0);

    /**
     * The currently selected asset/group symbols to include in the withdrawal
     */
    const [selectedLookup, setSelectedLookup] = useState<Record<string, boolean>>({});

    /**
     * The function to handle the change in checked state
     * @param {Object} updates - The updates to the checked state
     * @returns {void}
     */
    const handleCheckedChange = useCallback(
      (updates: { [symbol: string]: boolean }) =>
        setSelectedLookup((prev) => ({ ...prev, ...updates })),
      [],
    );

    /**
     * The hook to get the user's pending/completable withdrawals, separated into
     * single strategy and multi-strategy withdrawals
     */
    const {
      data: { single, batches },
      isLoading: isLoadingBuckets,
    } = useGetAllWithdrawalBuckets();

    /**
     * The selected withdrawals to include in the withdrawal, derived from the `selectedLookup` symbols
     */
    const selectedWithdrawals = useMemo(() => {
      const allWithdrawals = { ...single, ...batches };
      const withdrawalKey = `${withdrawalFieldKey}s` as const;
      return Object.entries(selectedLookup).reduce((acc, [symbol, isSelected]) => {
        const withdrawals = allWithdrawals[symbol]?.[withdrawalKey] ?? [];
        return isSelected ? [...acc, ...withdrawals] : acc;
      }, []);
    }, [withdrawalFieldKey, selectedLookup, single, batches]);

    const {
      /**
       * The contract call to complete the user's withdrawals
       */
      completeWithdrawal,
      /**
       * The contract call to complete the user's withdrawals to the eigen layer
       */
      completeWithdrawalToEigenLayer,
      unstakeMenuProps: {
        /**
         * A dictionary of the withdrawal amounts for each tokens symbol
         */
        withdrawalAmounts,
      },
    } = useCompleteWithdrawals({
      withdrawals: selectedWithdrawals,
      enabled: !!selectedWithdrawals.length,
    });

    /**
     * The single-strategy withdrawals that have an amount to withdraw
     */
    const singleWithdrawals = useMemo(() => {
      const amountKey = `${withdrawalFieldKey}Amount` as const;
      return Object.values(single ?? {})
        .filter((t) => !!t[amountKey])
        .sort((a, b) => (a[amountKey] < b[amountKey] ? 1 : -1));
    }, [single, withdrawalFieldKey]);

    /**
     * The multi-strategy withdrawals that have an amount to withdraw
     */
    const batchWithdrawals = useMemo(() => {
      const amountKey = `${withdrawalFieldKey}Amounts` as const;
      return Object.values(batches ?? {})
        .filter((t) => t[amountKey].some(Boolean))
        .sort((a, b) => (a?.tokens[0].name < b?.tokens[0].name ? -1 : 1));
    }, [batches, withdrawalFieldKey]);

    /**
     * The effect to update the footer height when the components load and
     * also when the window is resized
     */
    useEffect(() => {
      if (!footerRef.current) {
        return;
      }

      const updateFooterHeight = () => {
        const footer = footerRef.current!;
        setFooterHeight(footer.clientHeight ?? 0);
      };

      updateFooterHeight();
      window.addEventListener('resize', updateFooterHeight);

      return () => window.removeEventListener('resize', updateFooterHeight);
      // NOTE: It says we don't need to watch `footerRef.current` but we do.
      //       Does it make sense? No. Does it fix random edgecases? Yes.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [footerRef.current, footerRef]);

    /**
     * The wrapper component for the body. It will render a form if the user can complete
     * the withdrawal, otherwise it will render a div
     * @param {Object} props - The props for the wrapper
     * @param {React.ReactNode} props.children - The children of the wrapper
     * @returns {JSX.Element} The wrapper component
     */
    const Wrapper = useCallback(
      ({ children }: { children: React.ReactNode }) => {
        const Slot = canCompleteWithdraw ? 'form' : 'div';
        return (
          <Slot className="flex h-full max-h-[calc(100vh-80px)] flex-col overflow-hidden">
            {children}
          </Slot>
        );
      },
      [canCompleteWithdraw],
    );

    return (
      <>
        <Wrapper>
          <div
            className="flex h-full w-full flex-col overflow-auto"
            style={{ height: `calc(100% - ${footerHeight - 24}px)` }}
          >
            {withdrawalFieldKey === 'completableWithdrawal' && (
              <TextV2 intent="TextS" className="mb-4 text-blue-800">
                Select {batchWithdrawals.length ? 'what' : 'the tokens that'} that you would like to
                withdraw
              </TextV2>
            )}
            {isLoadingBuckets && (
              <>
                <div className="mb-2 h-4 w-[45%] animate-pulse rounded-md bg-blue-800 bg-opacity-20" />
                <WithdrawalItemWrapper canCompleteWithdraw={canCompleteWithdraw} />
                <WithdrawalItemWrapper canCompleteWithdraw={canCompleteWithdraw} />
              </>
            )}
            {!isLoadingBuckets && (
              <>
                <TextV2 intent="TextXS" className="mb-2 font-bold text-blue-800 opacity-40">
                  {batchWithdrawals.length
                    ? 'Single-Token withdrawals'
                    : `Assets ${canCompleteWithdraw ? 'awaiting' : 'pending'} withdrawal`}
                </TextV2>
                {singleWithdrawals.map((withdrawals) => (
                  <WithdrawalItem
                    key={withdrawals.symbol}
                    withdrawals={withdrawals}
                    withdrawalFieldKey={withdrawalFieldKey}
                    onClose={onClose}
                    checked={selectedLookup[withdrawals.symbol]}
                    onCheckedChange={handleCheckedChange}
                  />
                ))}

                {!!batchWithdrawals.length && (
                  <TextV2 intent="TextXS" className="my-2 font-bold text-blue-800 opacity-40">
                    Multi-Token withdrawals
                  </TextV2>
                )}
                {batchWithdrawals.map((withdrawals) => (
                  <WithdrawalItem
                    key={withdrawals.symbols}
                    withdrawals={withdrawals}
                    withdrawalFieldKey={withdrawalFieldKey}
                    onClose={onClose}
                    checked={selectedLookup[withdrawals.symbols]}
                    onCheckedChange={handleCheckedChange}
                  />
                ))}
                {!singleWithdrawals.length && !batchWithdrawals.length && (
                  <div className="flex h-[300px] max-h-full w-full flex-col items-center justify-center">
                    <TextV2 intent="TextM" className="text-center text-blue-800">
                      No withdrawals available
                    </TextV2>
                  </div>
                )}
              </>
            )}
          </div>
          {canCompleteWithdraw && (
            <CompleteWithdrawalFooter
              ref={footerRef}
              onClose={onClose}
              completeWithdrawal={completeWithdrawal}
              completeWithdrawalToEigenLayer={completeWithdrawalToEigenLayer}
              withdrawalTokenCount={Object.keys(withdrawalAmounts).length}
            />
          )}
        </Wrapper>
      </>
    );
  },
);
DashboardWithdrawalSheetBody.displayName = 'DashboardWithdrawalSheetBody';

// ===========================
//    Subcomponents
// ===========================

/**
 * The wrapper for the withdrawal item
 * @param {Object} props - The props for the wrapper
 * @param {React.ReactNode} props.children - The children of the wrapper
 * @param {boolean} props.canCompleteWithdraw - Whether the withdrawal can be completed
 * @returns {JSX.Element} The withdrawal item wrapper component
 */
function WithdrawalItemWrapper({
  children,
  canCompleteWithdraw,
}: {
  children?: React.ReactNode;
  canCompleteWithdraw?: boolean;
}) {
  return (
    <div
      className={cn(
        'mb-2 flex max-w-full flex-row items-center justify-between gap-4 overflow-hidden rounded-lg border-slate-200 bg-slate-50 px-2 py-2',
        children && 'hover:rounded-lg hover:bg-slate-200',
        !children && 'animate-pulse',
      )}
    >
      {children ?? <div className={canCompleteWithdraw ? 'h-6' : 'h-10'} />}
    </div>
  );
}

/**
 * The withdrawal item component
 * @param {Object} props - The props for the withdrawal item
 * @param {UncompletedMultiAssetWithdrawalsWithToken | UncompletedWithdrawalsWithToken} props.withdrawals - The withdrawals to display
 * @param {"completableWithdrawal" | "pendingWithdrawal"} props.withdrawalFieldKey - The key for the withdrawal field
 * @param {boolean} props.checked - Whether the withdrawal is checked
 * @param {() => void} props.onClose - The function to close the sheet
 * @param {(updates: { [symbol: string]: boolean }) => void} props.onCheckedChange - The function to change the checked state
 * @returns {JSX.Element} The withdrawal item component
 */
const WithdrawalItem = memo(
  ({
    withdrawals,
    withdrawalFieldKey,
    checked,
    onClose,
    onCheckedChange,
  }: {
    withdrawals: UncompletedMultiAssetWithdrawalsWithToken | UncompletedWithdrawalsWithToken;
    withdrawalFieldKey: DashboardWithdrawalSheetBodyProps['withdrawalFieldKey'];
    checked?: boolean;
    onClose: () => void;
    onCheckedChange: (updates: { [symbol: string]: boolean }) => void;
  }) => {
    const { data: liquidRestakingTokens } = useLiquidTokens();
    const { data: nativeRestakingToken } = useNativeToken();

    const isCompletableSheet = withdrawalFieldKey === 'completableWithdrawal';
    const withdrawalsArrayKey = `${withdrawalFieldKey}s` as const;
    const isSingle = withdrawals.type === UncompletedWithdrawalType.SINGLE;
    const symbol = isSingle ? withdrawals.symbol : withdrawals.symbols;

    const nearestCompletableBlock = Math.min(
      ...withdrawals[withdrawalsArrayKey].map((w) => Number(w.completableBlock)),
    );

    const { data: timeUntilCompletable, isLoading: isCurrentBlockLoading } = useTimeToBlock({
      targetBlock: nearestCompletableBlock,
      useBlockNumberParams: { query: { refetchInterval: 60000 } },
    });

    /**
     * An array of token classes that are mapped to the withdrawal tokens
     */
    const tokenData = useMemo(() => {
      if (!nativeRestakingToken || !liquidRestakingTokens?.length) {
        return [];
      }

      const allTokens = [nativeRestakingToken!, ...liquidRestakingTokens];
      const withdrawalTokens = isSingle ? [withdrawals] : withdrawals.tokens;

      return withdrawalTokens.map((w) => allTokens.find((t) => t?.symbol === w.symbol)!);
    }, [isSingle, withdrawals, liquidRestakingTokens, nativeRestakingToken]);

    /**
     * The icons of the tokens
     */
    const tokenIcons = useMemo(() => tokenData.map(({ icon }) => icon), [tokenData]);

    /**
     * The function to handle the change in checked state
     */
    const handleCheckedChange = useCallback(
      (checked: boolean) => {
        onCheckedChange({ [symbol]: checked });
      },
      [onCheckedChange, symbol],
    );

    /**
     * JSX for time until the withdrawal is available
     */
    const timeUntilAvailableContent = useMemo(
      () =>
        isCurrentBlockLoading ? (
          <div className="bg-text-blue-800 h-4 w-12 animate-pulse rounded-sm bg-opacity-50" />
        ) : (
          <TextV2 intent="TextXS" weight="bold" className="opacity-50">
            ~{timeUntilCompletable}
          </TextV2>
        ),
      [isCurrentBlockLoading, timeUntilCompletable],
    );

    return (
      <WithdrawalItemWrapper>
        <div className="flex max-w-full flex-shrink items-center gap-2 overflow-hidden">
          {isCompletableSheet && (
            <Checkbox
              checked={checked}
              onCheckedChange={handleCheckedChange}
              className="flex-shrink-0 translate-x-px"
            />
          )}
          <TokenIconDisplay tokenIcons={tokenIcons} className="flex-shrink-0" />
          <div className="flex max-w-full flex-shrink flex-col gap-1 overflow-hidden">
            {tokenData.map((t) => (
              <Link
                href={`/restake/${t.symbol}?interact=withdraw`}
                onClick={onClose}
                key={t.symbol}
                className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
              >
                <TextV2
                  intent="TextS"
                  className="max-w-full overflow-hidden text-blue-500 underline underline-offset-1 sm:block"
                >
                  {t.name}
                </TextV2>
              </Link>
            ))}
          </div>
        </div>

        <div className="flex flex-shrink-0 flex-col items-end gap-1">
          {tokenData.map(
            (t, i) =>
              t && (
                <TextV2 key={i} intent="TextS" className="flex gap-1 text-blue-800">
                  <NumberDisplay
                    value={t.convertSharesToUnderlying(
                      (isSingle
                        ? withdrawals[`${withdrawalFieldKey}Amount`]
                        : withdrawals[`${withdrawalFieldKey}Amounts`][i]) ?? 0,
                      { format: 'decimal' },
                    )}
                    decimals={2}
                    format="decimal"
                  />
                  <span className="inline-block text-slate-900 sm:text-slate-400">{t.symbol}</span>
                </TextV2>
              ),
          )}
          {!isCompletableSheet && timeUntilAvailableContent}
        </div>
      </WithdrawalItemWrapper>
    );
  },
);
WithdrawalItem.displayName = 'WithdrawalItem';

/**
 * The footer of the sheet that displays the user's pending/completable withdrawals
 * @param {Object} props - The props for the footer
 * @param {() => void} props.onClose - The function to close the sheet
 * @param {() => void} props.completeWithdrawal - The function to complete the withdrawal
 * @param {() => void} props.completeWithdrawalToEigenLayer - The function to complete the withdrawal to the eigen layer
 * @param {number} props.withdrawalTokenCount - The number of tokens to withdraw
 * @returns {JSX.Element} The footer component
 */
const CompleteWithdrawalFooter = forwardRef<
  HTMLDivElement,
  {
    onClose: () => void;
    completeWithdrawal: () => void;
    completeWithdrawalToEigenLayer: () => void;
    withdrawalTokenCount;
  }
>(({ onClose, completeWithdrawal, completeWithdrawalToEigenLayer, withdrawalTokenCount }, ref) => (
  <div
    ref={ref}
    className="absolute bottom-0 left-0 right-0 flex flex-col gap-4 border-t border-t-black/10 bg-blue-100 p-6 pt-4"
  >
    <TextV2
      intent="TextM"
      className={cn(
        'flex w-full items-center justify-between rounded-md border-2 border-blue-500 bg-white/50 px-3 py-1.5 opacity-50 transition-opacity',
        !!withdrawalTokenCount && 'opacity-100',
      )}
    >
      <span>Tokens selected</span>
      <span>{withdrawalTokenCount}</span>
    </TextV2>
    <div className="flex w-full justify-center gap-2">
      <ButtonV2
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          onClose();
          completeWithdrawal();
        }}
        disabled={!withdrawalTokenCount}
        intent="primary"
        size={ComponentSize.SM}
        className="flex-1"
      >
        WITHDRAW
      </ButtonV2>
      <ButtonV2
        onClick={() => {
          onClose();
          completeWithdrawalToEigenLayer();
        }}
        disabled={!withdrawalTokenCount}
        intent="secondary"
        size={ComponentSize.SM}
        className="flex-1"
      >
        REDEPOSIT
      </ButtonV2>
    </div>
  </div>
));
CompleteWithdrawalFooter.displayName = 'CompleteWithdrawalFooter';

// ===========================
//    Exports
// ===========================

/**
 * The sheet that displays the user's pending/completable withdrawals
 */
export const DashboardWithdrawalSheet = {
  /**
   * The header of the sheet that displays the user's pending/completable withdrawals
   * @param {DashboardWithdrawalSheetHeaderProps} props - The props for the header
   * @returns {JSX.Element} The header component
   * @example
   * ```tsx
   * <DashboardWithdrawalSheet.Header
   *   ref={ref}
   *   title="Withdrawals"
   *   onClose={onClose}
   * />
   * ```
   */
  Header: DashboardWithdrawalSheetHeader,

  /**
   * The body of the sheet that displays the user's pending/completable withdrawals
   * @param {DashboardWithdrawalSheetBodyProps} props - The props for the body
   * @returns {JSX.Element} The body component
   * @example
   * ```tsx
   * <DashboardWithdrawalSheet.Body
   *  withdrawalFieldKey="completableWithdrawal"
   *  onClose={onClose}
   * />
   */
  Body: DashboardWithdrawalSheetBody,
};
