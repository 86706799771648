'use client';

import { useEffect } from 'react';

import { TransactionStatus } from '@layr-labs/eigen-kit/types';

import { useClaimFlowStore } from 'components/Claims/useClaimFlowStore';

import { api } from '@/utils/api';

import useAccount from './useAccount';

const useFetchUpcomingRewards = () => {
  const { address } = useAccount();
  const transaction = useClaimFlowStore((state) => state.transaction);

  const rewardsResponse = api.rewards.getClaimableRewards.useQuery(
    {
      earner: address ? address : '',
    },
    {
      enabled: !!address,
      initialData: {
        blockHeight: 0n,
        rewards: [],
      },
      trpc: { context: { skipBatch: true } },
    },
  );

  useEffect(() => {
    if (transaction.status === TransactionStatus.Success) {
      rewardsResponse.refetch();
    }
  }, [rewardsResponse, transaction.status]);

  return rewardsResponse;
};

export default useFetchUpcomingRewards;
