import { NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';

function TokenAmountCell({ weiAmount }: { weiAmount: bigint }) {
  return (
    <div className="flex items-center justify-end gap-1 py-3">
      <TextV2 intent="TextXS" className="text-[10px] text-blue-700 sm:text-[12px]">
        <NumberDisplay value={weiAmount} format="tokenAmount" decimals={4} />
      </TextV2>
    </div>
  );
}

export default TokenAmountCell;
