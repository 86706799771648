import { useMemo } from 'react';

import { formatUnits } from 'viem';

import { isLSTorNative, isToken } from '@/utils/index';

import { useTokens } from './useTokens';

export const useTokenStats = () => {
  const {
    data: { liquidRestakingTokens, nativeRestakingToken },
    isLoading: isTokensLoading,
  } = useTokens();

  const userTotalRestakedInETH = useMemo(() => {
    if (!liquidRestakingTokens || !nativeRestakingToken) {
      return null;
    }

    const userTotalRestakedInETH = [...liquidRestakingTokens, nativeRestakingToken].filter(
      (token) => isLSTorNative(token),
    );

    if (userTotalRestakedInETH.length === 0) {
      return 0;
    }

    return userTotalRestakedInETH
      .filter(isToken)
      .reduce(
        (total, token) =>
          total +
          token.ethValue * Number(formatUnits(BigInt(token.deposited.underlying), token.decimals)),
        0,
      );
  }, [liquidRestakingTokens, nativeRestakingToken]);

  const isRestaked: boolean = useMemo(() => {
    if (!liquidRestakingTokens || !nativeRestakingToken) {
      return false;
    }

    return [...liquidRestakingTokens, nativeRestakingToken].some(({ deposited }) => {
      if (deposited) {
        return deposited.underlying > 0n;
      }
    });
  }, [liquidRestakingTokens, nativeRestakingToken]);

  return {
    userTotalRestakedInETH: userTotalRestakedInETH ?? 0,
    isRestaked: isRestaked,
    isLoading: isTokensLoading,
    isSuccess: userTotalRestakedInETH !== null,
  };
};
