import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

import { ColDef } from 'ag-grid-community';

import { ComponentSize, TextInput, ValueLoader } from '@layr-labs/eigen-kit/react';
import { TokenSelection } from '@layr-labs/eigen-kit/types';

import { RewardsMode } from './ClaimModal';
import NameSymbolCell from './NameSymbolCell';
import TokenAmountCell from './TokenAmountCell';

const AgGridReactDynamic = dynamic(() => import('ag-grid-react').then((mod) => mod.AgGridReact), {
  ssr: false,
  loading: () => (
    <div className="mt-4 flex flex-col">
      <ValueLoader intent="TextL" className="mb-2 w-full" />
      <ValueLoader className="h-56" />
    </div>
  ),
});

function ClaimSelectionTable({
  data,
  setClaimSelection,
  rewardsMode,
}: {
  data: TokenSelection[];
  setClaimSelection: (TokenSelection: TokenSelection[]) => void;
  rewardsMode: RewardsMode;
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const colDefs: ColDef<TokenSelection, unknown>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'REWARD TOKEN',
        resizable: false,
        flex: 2,
        suppressMovable: true,
        headerCheckboxSelection: rewardsMode === 'select' ? true : false,
        checkboxSelection: rewardsMode === 'select' ? true : false,
        showDisabledCheckboxes: true,
        cellRenderer: (props) => (
          <NameSymbolCell
            icon={props.data.icon}
            name={props.data.name}
            tokenAddress={props.data.address}
            rewardsMode={rewardsMode}
            symbol={props.data.symbol}
          />
        ),
      },
      {
        field: 'weiAmount',
        headerName: 'CLAIMABLE',
        resizable: false,
        flex: 1,
        suppressMovable: true,
        type: 'rightAligned',
        cellRenderer: (props) => <TokenAmountCell weiAmount={props.data.weiAmount} />,
      },
    ],
    [rewardsMode],
  );
  return (
    <div className="ag-theme-quartz ag-theme-eigen h-full w-full px-4">
      {rewardsMode === 'select' && (
        <TextInput
          search={true}
          placeholder="Search"
          size={ComponentSize.SM}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      )}
      <AgGridReactDynamic
        rowData={data}
        className="pb-6"
        domLayout="normal"
        quickFilterText={searchTerm}
        rowClass="hover:bg-blue-100 cursor-pointer"
        columnDefs={colDefs}
        rowSelection="multiple"
        rowMultiSelectWithClick={true}
        suppressRowHoverHighlight={true}
        onRowSelected={({ api }) => setClaimSelection(api.getSelectedRows() as TokenSelection[])}
        isRowSelectable={() => rewardsMode === 'select'}
      />
    </div>
  );
}

export default ClaimSelectionTable;
