import { useMemo } from 'react';

import { Address } from 'abitype';

import { LifetimeEarnedResponse } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';
import { tokenRewardsToTokenSelections } from '@/utils/rewardsUtils';

/**
 * This hook fetches the amount of lifetime earned tokens for a given earner.
 *
 * @param {Address} earner - The address of the earner.
 *
 * @returns An object containing:
 * - `lifetimeEarnedTokens`: The amount of lifetime earned tokens for the given earner.
 * - `isLifetimeEarnedTokensLoading`: A boolean indicating whether the request is currently loading.
 * - `lifetimeEarnedTokensError`: An error object containing the error message.
 * - `isLifetimeEarnedTokensSuccess`: A boolean indicating whether the request was successful.
 */

const useFetchLifetimeEarned = (earner?: Address) => {
  // const { data, isLoading, error, isSuccess } =
  const { data } = api.rewards.getLifetimeEarned.useQuery<LifetimeEarnedResponse>(
    {
      earnerAddress: earner ?? '',
    },
    {
      enabled: !!earner,
      trpc: { context: { skipBatch: true } },
    },
  );

  const lifetimeEarnedTokens = useMemo(() => {
    if (data?.rewards) {
      return tokenRewardsToTokenSelections(data.rewards);
    }
    return [];
  }, [data]);

  return {
    lifetimeEarnedTokens,
    isLifetimeEarnedTokensLoading: false,
    lifetimeEarnedTokensError: null,
    isLifetimeEarnedTokensSuccess: true,
    // isLifetimeEarnedTokensLoading: isLoading,
    // lifetimeEarnedTokensError: error,
    // isLifetimeEarnedTokensSuccess: isSuccess,
  };
};

export default useFetchLifetimeEarned;
