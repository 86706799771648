import { Address } from 'abitype';
import { create } from 'zustand';

import { TransactionStatus } from '@layr-labs/eigen-kit/types';

export type ClaimTransaction = {
  status: TransactionStatus;
  message?: React.ReactNode;
  hash: Address | null;
};

const NEW_TRANSACTION: ClaimTransaction = {
  status: TransactionStatus.NotStarted,
  hash: null,
};

type ClaimFlowState = {
  transaction: ClaimTransaction;
  updateTransaction: (updatedTxn: Partial<ClaimTransaction>) => void;
  resetTransaction: () => void;
};

export const useClaimFlowStore = create<ClaimFlowState>((set) => ({
  transaction: NEW_TRANSACTION,
  updateTransaction: (updatedTxn) =>
    set((state) => ({
      transaction: { ...state.transaction, ...updatedTxn },
    })),
  resetTransaction: () => set({ transaction: NEW_TRANSACTION }),
}));
