import { useMemo } from 'react';

import { Address } from 'abitype';
import _, { find, reduce, sumBy } from 'lodash';

import { OperatorType } from '@layr-labs/eigen-kit/types';

import { useTokens } from '@/hooks/useTokens';

import { api } from '@/utils/api';
import { isToken } from '@/utils/index';

import { useDelegatedTo } from './interactions/useDelegatedTo';

export const useFetchOperatorAggregateShares = () => {
  const { data: tvlStakerResponse, isLoading: isTvlStakerDataLoading } =
    api.operator.getAllOperatorShares.useQuery(undefined, {
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 5,
      select: (data) => {
        if (data.strategyAndShare.length === 0) {
          return {};
        }

        return data.strategyAndShare.reduce((acc, shares) => {
          Object.entries(shares).forEach(([strategy, share]) => {
            if (!acc[strategy]) {
              acc[strategy] = 0n;
            }

            acc[strategy] += BigInt(share ?? 0n);
          });
          return acc;
        }, {});
      },
    });

  const {
    data: { strategyTokenMap },
    isLiquidTokensLoading,
    isNativeTokenLoading,
  } = useTokens();

  const delegatedTvlTotal = useMemo(() => {
    if (!tvlStakerResponse || !strategyTokenMap) {
      return { eigenTVL: 0, totalTVL: 0 };
    }
    const tvl = Object.entries(tvlStakerResponse).map(([strategy, share]) => {
      const token = strategyTokenMap[strategy];
      if (!token) {
        return;
      }
      const underlying = Number(
        token.convertSharesToUnderlying(BigInt(share), { format: 'decimal' }),
      );
      return {
        ...token,
        ethValue: token.ethValue * underlying,
        tvl: underlying,
      };
    });

    const tvlByGroup = _.chain(tvl).groupBy('group').value();

    const totalTVL =
      sumBy(tvlByGroup['native'], 'ethValue') +
      sumBy(tvlByGroup['lst'], 'ethValue') +
      sumBy(tvlByGroup['WETH'], 'ethValue');

    const eigenTVL = find(tvl, { slug: 'EIGEN' })?.tvl ?? 0;

    return { eigenTVL, totalTVL };
  }, [strategyTokenMap, tvlStakerResponse]);

  return {
    data: delegatedTvlTotal,
    isLoading: isLiquidTokensLoading || isNativeTokenLoading || isTvlStakerDataLoading,
  };
};

const useFetchOperators = () => {
  const {
    data: metadataResponse,
    isLoading: isMetadataLoading,
    isSuccess: isMetadataSuccess,
  } = api.operator.getAllOperatorsWithMetadata.useQuery(undefined, {});

  const {
    data: tvlStakerResponse,
    isLoading: isTvlStakerDataLoading,
    isSuccess: isTvlStakerDataSuccess,
  } = api.operator.getAllOperatorTVLNumStakers.useQuery(undefined, {});

  const {
    data: { strategyTokenMap },
    isLiquidTokensLoading,
    isNativeTokenLoading,
  } = useTokens();

  const tvlStakerMap = useMemo(() => {
    return (
      tvlStakerResponse?.operatorTvlStakerData?.reduce((acc, datum) => {
        acc[datum.address] = datum;
        return acc;
      }, {}) ?? {}
    );
  }, [tvlStakerResponse?.operatorTvlStakerData]);

  const metadata = metadataResponse?.operatorMetadata;

  const formattedOperators = useMemo<OperatorType[]>(() => {
    if (!metadata || !tvlStakerMap || isLiquidTokensLoading || !strategyTokenMap) return [];

    const filtered = metadata.filter((m) => !!m?.name);

    return filtered.map<OperatorType>((m) => {
      const datum = tvlStakerMap[m.address] as Record<string, string>;

      const tvl = Object.entries(datum.shares).map(([strategy, share]) => {
        const token = strategyTokenMap[strategy];
        if (!token) {
          return;
        }
        const underlying = Number(
          token.convertSharesToUnderlying(BigInt(share), { format: 'decimal' }),
        );
        return {
          ...token,
          ethValue: token.ethValue * underlying,
          tvl: underlying,
        };
      });

      const tvlByGroup = _.chain(tvl).groupBy('group').value();

      const totalTVL =
        sumBy(tvlByGroup['native'], 'ethValue') +
        sumBy(tvlByGroup['lst'], 'ethValue') +
        sumBy(tvlByGroup['WETH'], 'ethValue');

      return {
        ...m,
        numStakers: datum?.numStakers || 1,
        numAvs: datum?.numAvs || 0,
        tvlByGroup,
        totalTVL,
        eigenTVL: find(tvl, { slug: 'EIGEN' })?.tvl ?? 0,
      } as OperatorType;
    });
  }, [metadata, tvlStakerMap, isLiquidTokensLoading, strategyTokenMap]);

  // NOTE: Remove this when api is created to aggergate delegated TVL
  const delegatedTvlTotal = useMemo(
    () =>
      reduce(
        formattedOperators,
        (acc, operator) => {
          acc.totalTVL += operator.totalTVL;
          acc.eigenTVL += operator.eigenTVL;
          return acc;
        },
        { totalTVL: 0, eigenTVL: 0 },
      ),
    [formattedOperators],
  );

  return {
    operatorMetadata: metadata,
    isMetadataLoading,
    isMetadataSuccess,
    isTvlStakerDataLoading,
    isTvlStakerDataSuccess,
    formattedOperators,
    delegatedTvlTotal,
    isLoading:
      isLiquidTokensLoading || isNativeTokenLoading || isTvlStakerDataLoading || isMetadataLoading,
  };
};

export const useFetchOperator = (operatorAddress?: Address) => {
  const { data: delegateAddress } = useDelegatedTo();
  const {
    data: operatorSummary,
    isLoading: isOperatorLoading,
    isSuccess: isOperatorSuccess,
    ...props
  } = api.operator.getOperatorSummary.useQuery({
    address: operatorAddress?.toLowerCase(),
    delegateAddress: delegateAddress?.toLowerCase(),
  });

  const {
    data: { strategyTokenMap },
    isLoading: isTokensLoading,
  } = useTokens();

  const tvlData = useMemo(() => {
    const shares = operatorSummary?.shares;
    if (!shares || !strategyTokenMap) {
      return { eigenTVL: 0, totalTVL: 0, tvl: [] };
    }
    const tvl = Object.entries(shares[0])
      .map(([strategy, share]) => {
        const token = strategyTokenMap[strategy];
        if (!token) {
          return;
        }
        const underlying = Number(
          token.convertSharesToUnderlying(BigInt(share), { format: 'decimal' }),
        );
        return {
          ...token,
          ethValue: token.ethValue * underlying,
          tvl: underlying,
        };
      })
      .filter(isToken);

    const tvlByGroup = _.chain(tvl).groupBy('group').value();

    const totalTVL =
      sumBy(tvlByGroup['native'], 'ethValue') +
      sumBy(tvlByGroup['lst'], 'ethValue') +
      sumBy(tvlByGroup['WETH'], 'ethValue');

    const eigenTVL = find(tvl, { slug: 'EIGEN' })?.tvl ?? 0;

    return { eigenTVL, totalTVL, tvl };
  }, [operatorSummary?.shares, strategyTokenMap]);

  return {
    ...props,
    operatorSummary:
      operatorSummary === null || operatorSummary === undefined
        ? null
        : {
            ...operatorSummary,
            numStakers: operatorSummary?.numStakers || 1,
            tvl: tvlData?.tvl,
            totalTVL: tvlData?.totalTVL,
            eigenTVL: tvlData?.eigenTVL,
          },
    isOperatorLoading: isTokensLoading || isOperatorLoading,
    isOperatorSuccess,
  };
};

export default useFetchOperators;
