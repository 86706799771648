import { ValueLoader } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';

export default function LoadingCard({ className }: { className?: string }) {
  return (
    <div className={cn('flex min-h-[186px] flex-col gap-2 rounded-lg p-2', className)}>
      <ValueLoader className="w-1/3" />
      <ValueLoader intent="TextS" className="h-full flex-grow" />
    </div>
  );
}
