'use client';

import { memo, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';

import { XCircleIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';

import { ButtonV2, ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';
import { TokenSelection, TransactionStatus } from '@layr-labs/eigen-kit/types';

import useClaim from '@/hooks/interactions/useClaim';

import ClaimsSelectionTable from './ClaimSelectionTable';
import NameSymbolCell from './NameSymbolCell';
import TokenAmountCell from './TokenAmountCell';
import TransactionReceipt from './TransactionReceipt';
import { useClaimFlowStore } from './useClaimFlowStore';

type ClaimsSelectionModalProps = {
  tokenClaims: TokenSelection[];
  isOpen: boolean;
  onClose: () => void;
};

export type RewardsMode = 'select' | 'claim';

function ClaimModal({ tokenClaims, isOpen, onClose }: ClaimsSelectionModalProps) {
  const [data, setData] = useState<TokenSelection[]>([]);
  const [claimButtonText, setClaimButtonText] = useState('SELECT TOKENS');
  const [rewardsMode, setRewardsMode] = useState<RewardsMode>('select');
  const transaction = useClaimFlowStore((state) => state.transaction);

  const {
    setClaimSelection,
    claimSelection,
    isClaimButtonDisabled,
    claimProofError,
    claimConfigError,
    isClaimConfigLoading,
    isClaimProofOrConfigLoading,
    claim,
  } = useClaim();

  useEffect(() => {
    if (tokenClaims) {
      setData(tokenClaims);
    }
  }, [tokenClaims]);

  useEffect(() => {
    if (!claimSelection.length) {
      return setClaimButtonText('SELECT TOKENS');
    }

    setClaimSelection(claimSelection);
    setClaimButtonText(`Claim ${claimSelection.length} TOKENS`);
    // Note: The SetStateActions are not included in the adependency array
    //       because they are not expected to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimSelection]);

  const closeModal = useCallback(() => {
    setRewardsMode('select');
    setData(tokenClaims);
    setClaimSelection([]);
    onClose();
    // Note: isOpen is included in the dependency array above because onClose updates the state of isOpen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenClaims, isOpen]);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="fixed top-0 z-10 flex h-screen w-screen items-center justify-center bg-black-200"
          onClick={closeModal}
        />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-20 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-4 rounded-lg bg-white outline-none sm:top-1/3 sm:mt-20 sm:h-[600px] sm:w-[550px]">
          {rewardsMode === 'select' && !claimConfigError ? (
            <Dialog.Title className="flex w-full items-center justify-between px-4 pt-4">
              <TextV2 intent="TextL">Claim Rewards</TextV2>
              <XCircleIcon
                onClick={closeModal}
                className="h-6 w-6 cursor-pointer hover:text-blue-400"
              />
            </Dialog.Title>
          ) : (
            <>
              <TransactionReceipt
                configError={claimConfigError?.message}
                transaction={transaction}
              />
              <XCircleIcon
                onClick={closeModal}
                className="absolute right-4 top-4 h-6 w-6 cursor-pointer hover:text-blue-400"
              />
            </>
          )}

          {rewardsMode === 'select' ? (
            <ClaimsSelectionTable
              data={data}
              setClaimSelection={setClaimSelection}
              rewardsMode={rewardsMode}
            />
          ) : (
            <div className="flex h-full w-full flex-col overflow-y-auto px-4 text-blue-300">
              <div className="mb-2 flex flex-row items-center justify-between px-2">
                <TextV2 className="font-ibmPlexMono" intent="TextXS">
                  REWARD TOKEN
                </TextV2>
                <TextV2 className="font-ibmPlexMono" intent="TextXS">
                  {!claimConfigError &&
                  !isClaimConfigLoading &&
                  transaction.status === TransactionStatus.Success
                    ? 'CLAIMED'
                    : 'CLAIMABLE'}
                </TextV2>
              </div>
              {claimSelection.map((claim) => {
                return (
                  <div
                    className="flex flex-row items-center justify-between border-t border-t-blue-100 px-2"
                    key={claim.address}
                  >
                    <NameSymbolCell
                      icon={claim.icon}
                      name={claim.name}
                      symbol={claim.symbol}
                      tokenAddress={claim.address}
                      rewardsMode={rewardsMode}
                    />
                    <TokenAmountCell weiAmount={claim.weiAmount} />
                  </div>
                );
              })}
            </div>
          )}

          <div className="z-10 flex w-full rounded-b-lg bg-white p-4 shadow-up">
            {rewardsMode === 'select' ? (
              <ButtonV2
                intent="primary"
                size={ComponentSize.MD}
                onClick={() => {
                  setRewardsMode('claim');
                  claim();
                }}
                className="w-full uppercase text-white"
                disabled={isClaimButtonDisabled}
              >
                {isClaimProofOrConfigLoading ? (
                  <Image
                    src="/eigenGif/White-Eigen-Loader.gif"
                    height={24}
                    width={24}
                    alt="eigenlayer loader"
                    className="mx-auto rounded-lg"
                  />
                ) : (
                  claimButtonText
                )}
              </ButtonV2>
            ) : claimProofError ? (
              <ButtonV2
                intent="primary"
                size={ComponentSize.MD}
                onClick={claim}
                disabled={
                  isClaimButtonDisabled ||
                  isClaimConfigLoading ||
                  transaction.status === TransactionStatus.Pending
                }
                className="w-full uppercase text-white"
              >
                Retry Claim
              </ButtonV2>
            ) : (
              <ButtonV2
                intent="primary"
                size={ComponentSize.MD}
                disabled={isClaimConfigLoading || transaction.status === TransactionStatus.Pending}
                onClick={closeModal}
                className="w-full uppercase text-white"
              >
                Done
              </ButtonV2>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default memo(ClaimModal);
