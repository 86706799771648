import { Address } from 'abitype';

import { EarnerAPRForStrategy, EarnerAPRForStrategyResponse } from '@layr-labs/eigen-kit/types';

import { api } from '@/utils/api';

/**
 * This hook fetches the APR for a given earner across specified strategies.
 *
 * @param {Address} earner - The address of the earner.
 * @param {Address[]} strategyAddresses - An array of strategies from which
 *                                       the APR will be fetched.
 *
 * @returns An object containing:
 * - `earnerAPR`: The APR for the specified earner and strategies. Defaults to an empty object
 *               if no data is fetched.
 * - `isEarnerAPRLoading`: A boolean indicating whether the request is currently loading.
 */

const useFetchEarnerAPRs = (earner: Address, strategyAddresses: Address[]) => {
  // const { data, isLoading } =
  const { data } = api.rewards.getEarnerAPRForStrategy.useQuery<EarnerAPRForStrategyResponse>(
    {
      earnerAddress: earner as Address,
      strategyAddresses,
    },
    {
      enabled: !!earner && strategyAddresses.length > 0,
      trpc: { context: { skipBatch: true } },
    },
  );
  const earnerAPRs = data?.rewards ?? ([] as EarnerAPRForStrategy[]);

  return {
    earnerAPRs,
    // isEarnerAPRsLoading: isLoading,
    isEarnerAPRsLoading: false,
  };
};

export default useFetchEarnerAPRs;
