'use client';

import { memo, useMemo, useState } from 'react';

import { formatEther } from 'viem';

import { ButtonV2, ComponentSize, NumberDisplay, TextV2 } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';
import { EarnerUpcomingRewards } from '@layr-labs/eigen-kit/types';

import ClaimsModal from 'components/Claims/ClaimModal';
import TokenIcon from 'components/Token/Icon';
import TokenIconDisplay from 'components/Token/TokenIconDisplay';
import TokenRewardsDisplay from 'components/Token/TokenRewardsDisplay';

import useFetchUpcomingRewards from '@/hooks/useFetchUpcomingRewards';
import usePrices, { getPriceDataFromIdentifier } from '@/hooks/usePrices';

import { tokenAddressToToken } from '@/utils/index';
import { transformEarnerAVSRewards } from '@/utils/rewardsUtils';

import LoadingCard from './LoadingCard';

type RewardsOverviewCard = {
  className: string;
};

function RewardsOverviewCard({ className }: RewardsOverviewCard) {
  const { data: earnerAVSRewards, isLoading } = useFetchUpcomingRewards();

  const earnerRewards = earnerAVSRewards as EarnerUpcomingRewards;
  const tokenClaims = useMemo(() => {
    return transformEarnerAVSRewards(earnerRewards.rewards);
  }, [earnerRewards.rewards]);
  const eigenReward = tokenClaims.find((token) => token.symbol === 'EIGEN');
  const [isClaimsModalOpen, setClaimsModalOpen] = useState(false);

  const { data: priceData, isLoading: isLoadingPrices } = usePrices({
    version: 'v1',
  });

  const onClaimsModalOpenClose = () => {
    setClaimsModalOpen(!isClaimsModalOpen);
  };

  const memoizedTokenClaimETHValue = useMemo(() => {
    if (tokenClaims.length === 0 || !priceData?.tokenPrices) {
      return 0;
    }
    return tokenClaims.reduce((acc, token) => {
      const tokenAmount = Number(formatEther(token.weiAmount));
      const tokenValue = getPriceDataFromIdentifier(token, priceData.tokenPrices, 'v1').eth;
      return acc + tokenAmount * tokenValue;
    }, 0);
  }, [tokenClaims, priceData]);

  if (isLoading || isLoadingPrices) {
    return <LoadingCard className={className} />;
  }

  if (earnerAVSRewards instanceof Error) {
    return (
      <div className={cn('flex flex-col justify-between rounded-lg p-4', className)}>
        <TextV2 intent="TextS" className="text-red-400">
          {earnerAVSRewards.message}
        </TextV2>
      </div>
    );
  }

  return (
    <>
      <ClaimsModal
        tokenClaims={tokenClaims}
        isOpen={isClaimsModalOpen}
        onClose={onClaimsModalOpenClose}
      />
      <div className={cn('flex flex-col gap-2 rounded-lg p-4', className)}>
        <div className="mb-4 flex flex-col justify-between gap-1 px-2 md:mb-4">
          <TextV2 intent="TextXS" font="ibmPlexMono" className="uppercase text-blue-400">
            Claimable Rewards
          </TextV2>
          <TextV2 intent="DisplayS" className="text-blue-800" weight="medium">
            <NumberDisplay value={memoizedTokenClaimETHValue} format="tokenAmount" />
            <TextV2 intent="DisplayS" className="text-blue-400">
              {' '}
              ETH
            </TextV2>
          </TextV2>

          <div />
        </div>
        <div className="flex flex-col content-center sm:mt-auto">
          <div className="flex items-center justify-between border-t border-blue-100 p-2">
            <TextV2 intent="TextS" className="h-6 content-center text-blue-400">
              AVS rewards
            </TextV2>
            <ButtonV2
              intent="pill"
              className="px-2 py-0.5 md:hidden"
              onClick={onClaimsModalOpenClose}
            >
              <TokenIconDisplay tokenIcons={tokenClaims.map((token) => token.icon)} />
            </ButtonV2>
            <TokenRewardsDisplay
              tokenClaims={tokenClaims}
              onClaimsModalOpenClose={onClaimsModalOpenClose}
            />
          </div>
          <div className="flex items-center justify-between border-t border-blue-100 p-2">
            <TextV2 intent="TextS" className="h-6 content-center text-blue-400">
              EIGEN rewards
            </TextV2>
            {eigenReward ? (
              <div className="flex items-center gap-2">
                <TokenIcon
                  src={eigenReward.icon}
                  fill={false}
                  className="h-6 w-6 rounded-full border-2 border-blue-100 bg-blue-100"
                />
                <TextV2 intent="TextS" className="uppercase text-blue-800">
                  <NumberDisplay value={eigenReward.weiAmount} format="tokenAmount" />
                </TextV2>
              </div>
            ) : null}
          </div>
        </div>

        <ButtonV2
          intent="secondary"
          className="w-full uppercase"
          size={ComponentSize.XS}
          disabled={tokenClaims.length === 0}
          onClick={onClaimsModalOpenClose}
        >
          Claim Rewards
        </ButtonV2>
      </div>
    </>
  );
}

export default memo(RewardsOverviewCard);
