'use client';

import dynamic from 'next/dynamic';

import { ColDef } from 'ag-grid-community';

import { TextV2, ValueLoader } from '@layr-labs/eigen-kit/react';
import { TokenSelection } from '@layr-labs/eigen-kit/types';

import NameSymbolCell from 'components/Claims/NameSymbolCell';

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import 'components/Table/eigen-table.css';

import { memo } from 'react';

import TokenAmountCell from 'components/Claims/TokenAmountCell';
import { HeaderWithSortIcon } from 'components/Table/HeaderWithSort';

const AgGridReactDynamic = dynamic(() => import('ag-grid-react').then((mod) => mod.AgGridReact), {
  ssr: false,
  loading: () => <ValueLoader className="h-full" />,
});

const generateRewardTableColDefs = (
  hiddenCols: Array<string> = [],
): ColDef<TokenSelection, unknown>[] => [
  {
    field: 'name',
    headerName: 'REWARD TOKEN',
    resizable: false,
    hide: hiddenCols.includes('name'),
    suppressMovable: true,
    flex: 1,
    minWidth: 80,
    wrapHeaderText: true,
    wrapText: true,
    autoHeight: true,
    headerClass: 'ag-eigen-header-left',
    cellRenderer: (props) => (
      <NameSymbolCell
        icon={props.data.icon}
        name={props.data.name}
        tokenAddress={props.data.address}
        symbol={props.data.symbol}
        rewardsMode="claim"
      />
    ),
    headerComponent: HeaderWithSortIcon,
  },
  {
    field: 'weiAmount',
    hide: hiddenCols.includes('weiAmount'),
    headerName: 'AMOUNT',
    resizable: false,
    suppressMovable: true,
    flex: 1,
    minWidth: 80,
    wrapHeaderText: true,
    wrapText: true,
    autoHeight: true,
    type: 'rightAligned',
    headerClass: 'ag-eigen-header-right',
    valueFormatter: ({ value }) => Number(value as bigint).toString(),
    comparator: (a, b) => Number(a) - Number(b),
    cellRenderer: (props) => (
      <div className="mr-6">
        <TokenAmountCell weiAmount={props.data.weiAmount} />
      </div>
    ),
    headerComponent: HeaderWithSortIcon,
  },
];

// TODO: Add Pagination
function RewardsTable({
  rewardData,
  hiddenCols,
  isLoading = false,
}: {
  rewardData: TokenSelection[];
  hiddenCols?: Array<string>;
  isLoading?: boolean;
}) {
  if (isLoading) {
    return <ValueLoader className="h-full" />;
  }
  return (
    <div className="ag-theme-quartz ag-theme-eigen h-full w-full">
      <AgGridReactDynamic
        rowData={rewardData}
        animateRows={false}
        className="h-full w-full"
        domLayout="normal"
        columnDefs={generateRewardTableColDefs(hiddenCols)}
        suppressRowHoverHighlight={true}
        noRowsOverlayComponent={() => (
          <TextV2 intent="TextM" className="text-blue-400">
            No rewards available
          </TextV2>
        )}
      />
    </div>
  );
}

// NOTE: why is this memoized?
export default memo(RewardsTable);
