'use client';

import { useEffect, useState } from 'react';

import { Address } from 'abitype';
import { useDebounceValue } from 'usehooks-ts';
import { WriteContractErrorType } from 'viem';
import { useSimulateContract } from 'wagmi';

import { useWriteContractLifecycle } from '@layr-labs/eigen-kit/react';
import { IRewardsCoordinatorAbi } from '@layr-labs/eigen-kit/abi';
import { ClaimsEarnerLeaf, ClaimsProofResponse, TokenSelection } from '@layr-labs/eigen-kit/types';

import { useClaimFlowStore } from 'components/Claims/useClaimFlowStore';

import { config } from 'chain/Web3Provider';
import { stakeConfig } from '@/config';
import { api } from '@/utils/api';

import useAccount from '../useAccount';

const useClaim = () => {
  const [claimSelection, setClaimSelection] = useState<TokenSelection[]>([]);
  const [debouncedClaimSelection, setDebouncedClaimSelection] = useDebounceValue<TokenSelection[]>(
    claimSelection,
    1000,
  );
  const updateTransaction = useClaimFlowStore((state) => state.updateTransaction);
  useEffect(() => {
    setDebouncedClaimSelection(claimSelection);
  }, [claimSelection, setDebouncedClaimSelection]);
  const { address } = useAccount();

  const {
    data: claimProof,
    isLoading: isClaimProofLoading,
    isSuccess: isClaimProofSuccess,
    error: claimProofError,
    isFetched: isClaimProofFetched,
    isFetching: isClaimProofFetching,
  } = api.rewards.getClaimProofs.useQuery<ClaimsProofResponse>(
    {
      earner: address,
      tokenAddress: debouncedClaimSelection.map((claim) => claim.address),
    },
    {
      enabled: debouncedClaimSelection.length > 0,
      refetchOnWindowFocus: false,
      initialData: {
        proof: {
          rootIndex: 0,
          earnerIndex: 0,
          earnerTreeProof: '',
          earnerLeaf: {
            earner: '' as Address,
            earnerTokenRoot: '' as Address,
          } as ClaimsEarnerLeaf,
          leafIndices: [],
          tokenTreeProofs: [],
          tokenLeaves: [],
        },
      } as unknown as ClaimsProofResponse,
    },
  );

  const {
    rootIndex,
    earnerIndex,
    earnerTreeProof,
    earnerLeaf,
    leafIndices,
    tokenTreeProofs,
    tokenLeaves,
  } = claimProof?.proof || {};

  const processClaimArgs = {
    claim: {
      rootIndex: rootIndex,
      earnerIndex: earnerIndex,
      earnerTreeProof: earnerTreeProof as Address,
      earnerLeaf: earnerLeaf,
      tokenIndices: leafIndices,
      tokenTreeProofs: tokenTreeProofs,
      tokenLeaves: tokenLeaves,
    },
    recipient: address as Address,
  };

  const {
    data,
    refetch: claimRefetch,
    error: claimConfigError,
    isLoading: isClaimConfigLoading,
    isSuccess: isClaimConfigSuccess,
  } = useSimulateContract({
    address: stakeConfig.rewardsCoordinatorAddress,
    abi: IRewardsCoordinatorAbi,
    functionName: 'processClaim',
    args: [
      {
        rootIndex: processClaimArgs.claim.rootIndex,
        earnerIndex: processClaimArgs.claim.earnerIndex,
        earnerTreeProof: processClaimArgs.claim.earnerTreeProof,
        earnerLeaf: processClaimArgs.claim.earnerLeaf,
        tokenIndices: processClaimArgs.claim.tokenIndices,
        tokenTreeProofs: processClaimArgs.claim.tokenTreeProofs,
        tokenLeaves: processClaimArgs.claim.tokenLeaves,
      },
      address as Address,
    ],
    query: {
      enabled: isClaimProofSuccess && isClaimProofFetched,
      refetchOnWindowFocus: false,
    },
  });

  const { writeContract } = useWriteContractLifecycle(config, {
    updateTransaction,
    onMutate: () => {
      updateTransaction({
        message: 'Claiming...',
      });
    },
    onError: (error: WriteContractErrorType) => {
      updateTransaction({
        message: error.message || 'Failed to claim rewards.',
      });
    },
    onTxnSuccess: () => {
      updateTransaction({
        message: 'Rewards claimed successfully.',
      });
    },
  });

  const isClaimProofOrConfigLoading =
    isClaimConfigLoading ||
    isClaimProofFetching ||
    claimSelection.length !== debouncedClaimSelection.length;

  return {
    claim: () => data?.request && writeContract(data.request),
    claimRefetch,
    claimConfigError,
    claimSelection,
    isClaimProofLoading,
    isClaimProofSuccess,
    isClaimButtonDisabled:
      claimSelection.length === 0 || !isClaimConfigSuccess || isClaimProofOrConfigLoading,
    claimProofError,
    setClaimSelection,
    isClaimConfigLoading,
    isClaimProofOrConfigLoading,
  };
};

export default useClaim;
