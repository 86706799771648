import { TextV2 } from '@layr-labs/eigen-kit/react';

import useAccount from '@/hooks/useAccount';
import useFetchLifetimeEarned from '@/hooks/useFetchLifetimeEarned';

import RewardsTable from './RewardsTable';

function LifetimeRewardsTable() {
  const { address, isConnected } = useAccount();

  const { lifetimeEarnedTokens, isLifetimeEarnedTokensLoading } = useFetchLifetimeEarned(address);

  if (!isConnected) {
    return null;
  }

  return (
    <div className="flex h-80 flex-col justify-between gap-2 rounded-lg bg-white p-2">
      <TextV2 intent="TextL" className="mx-2 text-blue-800">
        Lifetime earned rewards
      </TextV2>
      <RewardsTable rewardData={lifetimeEarnedTokens} isLoading={isLifetimeEarnedTokensLoading} />
    </div>
  );
}

export default LifetimeRewardsTable;
